import React from 'react'

const Details = ({ userData }) => {
  return (
    <div className="border rounded p-3 bg-light mt-md-0 mt-3">
      <p className="border-bottom" style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Details</p>
      <div className="mb-3">
        <h5>Name: </h5>
        <div>{userData.name}</div>
      </div>
      <div className="mb-3">
        <h5>Email: </h5>
        <div>{userData.email}</div>
      </div>
      <div className="mb-3">
        <h5>Instagram Id: </h5>
        <a href={`https://instagram.com/${userData.instagramId}`}>{userData.instagramId || `not available`}</a>
      </div>
      <div className="mb-3">
        <h5>Phone Number: </h5>
        <div>{userData.phoneNo || `not available`}</div>
      </div>
      <div className="mb-3">
        <h5>Current City: </h5>
        <div>{userData.currentCity || `not available`}</div>
      </div>
      <div className="mb-3">
        <h5>Donating: </h5>
        <div>{userData ?
          userData.isDonating ? "YES" : "NO"
          : null}</div>
      </div>
    </div>
  )
}

export default Details
