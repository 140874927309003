import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'


const StyledHero = styled.div`
  height: 300px;
  background-image: url("/images/hero.webp");
  background-size: cover;
  display:flex;
  align-items:center;
  justify-content:center;
  color: white;
  flex-flow:column;
    
`

const HeroSection = ({ isSignedIn, GoogleBtn, user, googleUser }) => {

  const UserLink = (
    <div className="h5 text-white text-center">
      <Link className="btn btn-light m-3" to="/user">
        <img className="mr-2" style={{ height: "40px", width: "40px" }} src={user.photoURL} alt={user.name} />
        <span className="mx-2">{user?.name}'s Profile</span>
      </Link>
      <p className="small mt-2">Please keep your profile updated.</p>
    </div>
  )


  return (
    <StyledHero>
      <h1 className="display-1 text-center mb-3">Donate Your Plasma</h1>
      {isSignedIn ? UserLink : GoogleBtn}
      {/* <p><SignOutBTn /></p> */}
    </StyledHero>
  )
}

export default HeroSection
