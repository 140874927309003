import React, { useState, useEffect } from 'react'
import UserForm from '../components/UserForm'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'

//database
import firebase from '../services/firebase'
import Details from '../components/Details'
const database = firebase.firestore()

const Div = styled.div`
  background-image: url("/images/hero.webp");
  height: 200px;
  position: relative;
  z-index: -1;
`

const BackBtn = styled.div`
  border: 2px solid white;
  color: white;
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;

  &:hover{
    background-color:white;
    color: black;
  }

`

const LoadingDiv = styled.div`
  height: 100vh;
  width: 100vh;
  display : flex;
  justify-content: center;
  align-items:center;
  margin:auto;
`


const UserDetails = ({ SignOutBTn, user }) => {


  const history = useHistory();

  const [userData, setUserData] = useState({})
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {

    const getUserData = async () => {
      const doc = await database.collection("users").doc(user.uid).get()
      setUserData(doc.data())
      console.log(doc.data())
      setIsLoading(true)
    }

    getUserData();
  }, [])


  if (!isLoading) return <LoadingDiv><h4>Loading...</h4></LoadingDiv>

  const userImage = (
    <div className="col-12 col-md-4 d-flex justify-content-center ">
      <img className="img-thumbnail" src={user.photoURL} alt="" />
    </div>
  )

  const infoText = (
    <>
      <div className="text-center mt-3">If you are not Donating you will not appear in the search.</div>
      <div className="text-center mb-3">If you had donated please turn off your donation check box.</div>
    </>
  )

  const userForm = (
    <div className="border rounded bg-light p-3 mt-md-0 mt-3" >
      <div className="border-bottom" >
        <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>Form</p>
        <p className="small">Please keep these details up to date.</p>
      </div>
      <UserForm user={userData} uid={user.uid} />
    </div>
  )

  return (


    <div>
      <Div />

      <BackBtn onClick={history.goBack}>
        <span>Go Back</span>
      </BackBtn>

      <div style={{ marginTop: "-50px" }}>
        <div className="container">

          <div className="row">
            {userImage}
          </div>

          <div className="row mt-5 mb-5">
            {infoText}

            <div className="col-12 col-md-5 offset-md-1 ">
              <Details userData={userData} />
            </div>

            <div className="col-12 col-md-4">
              {userForm}
              <div className="py-3 mt-3 d-grid">
                {SignOutBTn}
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default UserDetails
