import React, { useState, useEffect } from 'react'
import allCitiesImported from '../Data/IndianCities.json'
import styled from '@emotion/styled'

import User from './User'

//database
import firebase from '../services/firebase'
const database = firebase.firestore()


const CustomSelect = styled.div`
  max-height: 200px;
  overflow-Y: scroll;
  overflow-X: hidden;
  margin-top: 10px;
  border: 2px solid #cfcfcf;
  display: ${props => props.open ? "block" : "none"};

  & > div{
    height: 2.3rem;
    padding: 5px;
    border-bottom: 1px solid #cfcfcf;
    margin: 5px;
    cursor: pointer;
  }
`

const NoDonorDiv = styled.div`
  text-align:center;
  margin-bottom: 100px;
  margin-top: 100px;
  padding: 10px;
  border: 2px solid #eee;
`


const DonarSearch = () => {


  const [allCities, setAllCities] = useState([])

  const [allUsers, setAllUsers] = useState([])
  const [allUsersReady, setAllUsersReady] = useState(false)

  useEffect(() => {
    setAllCities(allCitiesImported)
  }, [])

  const [city, setCity] = useState("")

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isCityError, setIsCityError] = useState(false)

  const checkInputForError = (value, name) => {
    if (name === "city") {
      if (allCitiesImported.some(city => city === value)) {
        setIsCityError(false)
      } else setIsCityError(true)
    }
  }

  const sortCities = (value) => {
    if (value.trim() === "") {
      setAllCities(allCitiesImported);
      return;
    }
    const sortedCities = allCitiesImported.filter(city => city.toLowerCase().includes(value.toLowerCase()))
    setAllCities(sortedCities);
  }


  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "city") {
      setCity(value);
      sortCities(value);
      checkInputForError(value, name)
    }
  }

  const handleSelectClick = (e) => {
    const value = e.target.innerHTML;
    setCity(value);
    checkInputForError(value, "city")
    setIsDropdownOpen(false)
  }

  const handleCityInputFocus = () => {
    setIsDropdownOpen(true);
  }

  const handeSearch = (e) => {
    e.preventDefault();
    setAllUsersReady(false)
    setAllUsers([])
    database.collection("users").where("currentCity", "==", city).where("isDonating", "==", true).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        setAllUsers(allUsers => [...allUsers, doc.data()])
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });
      setAllUsersReady(true)
    })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }

  // if (allUsersReady) console.log(allUsers)

  return (
    <>
      <div className="container" style={{ maxWidth: "400px" }}>
        <div className="small mb-3">No Donars in your city? Try searching in nearby cities.</div>
        <label className="form-label" htmlFor="city"><div className="h4">Search for Donor in your Current City</div> </label>
        <form className="" action="" method="post">
          <div className="mb-3">

            <input className="form-control" value={city} type="text" name="city" id="city" onChange={handleInputChange} onFocusCapture={handleCityInputFocus} />

            {isCityError && <span className="text-red small">Please select a city from the Dropdown</span>}

            <CustomSelect open={isDropdownOpen} >
              {allCities.map((city, index) => (
                <div onClick={handleSelectClick} key={`${city}-${index}`}>{city}</div>
              ))}
            </CustomSelect>
          </div>
          <div >
            <button disabled={!city || isCityError} type="submit" className="btn btn-primary" onClick={handeSearch}>Search</button>
          </div>
        </form>

      </div>

      <div className="container-fluid  mt-5 ">
        <div className="row">
          <div className="d-flex justify-content-center" style={{ flexWrap: "wrap", flexGrow: "1" }}>
            {allUsersReady && allUsers.map(user => (
              <User name={user.name} email={user.email} phoneNo={user.phoneNo} instagramId={user.instagramId} city={user.currentCity} />
            ))}
          </div>
          <div className="col-12 col-md-4 offset-md-4">
            {!allUsers.length && allUsersReady &&
              <NoDonorDiv>No Donars in This City.</NoDonorDiv>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default DonarSearch

