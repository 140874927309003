import React from 'react'
import DonarSearch from '../components/DonarSearch';
import HeroSection from '../components/HeroSection';
// import User from '../components/User'




const Home = ({ isSignedIn, GoogleBtn, user }) => {

  return (
    <div>
      <HeroSection isSignedIn={isSignedIn} GoogleBtn={GoogleBtn} user={user} />

      <div className="container mt-5">

        <DonarSearch />

      </div>
    </div>
  )
}

export default Home
