import Home from './pages/Home';
import UserDetails from './pages/UserDetails'
import firebase from 'firebase'
import React, { useState, useEffect } from 'react'
import { StyledFirebaseAuth } from 'react-firebaseui';
import './services/firebase'

// react router 
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

// notifications
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'

const database = firebase.firestore()

const uiConfig = {
  signInFlow: "popup",
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false
  }
}


function App() {

  const [isSignedIn, setIsSignedIn] = useState(false);

  const [userInState, setUserInState] = useState({})


  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user)
      // check if user exists in the database
      if (!!user) checkIfUserExists(user);
    })
  }, [])


  const checkIfUserExists = (user) => {
    database.collection("users").doc(user.uid).get().then(doc => {
      if (doc.exists) {
        console.log("user already exist...");
        setUserInState(doc.data())
      }
      else saveUserToDatabase(user);
    }).catch(error => console.log("error getting document...", error))
  }


  const saveUserToDatabase = (user) => {
    const newUser = {
      name: user.displayName,
      email: user.email,
      currentCity: "",
      phoneNo: "",
      isDonating: false,
      uid: user.uid,
      photoURL: user.photoURL,
      instagramId: ""
    }
    database.collection("users").doc(user.uid).set(newUser)
      .then(function () {
        console.log("Document successfully written!");
        setUserInState(newUser);
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
  }


  const GoogleBtn = (
    <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
  )

  const signOut = () => {
    firebase.auth().signOut();
    setIsSignedIn(false)
  }

  const SignOutBTn = (
    <button onClick={signOut} className="btn btn-outline-danger">Sign Out</button>
  )

  return (
    <div>
      <ToastContainer closeButton={true} position="bottom-right" />
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home SignOutBTn={SignOutBTn} isSignedIn={isSignedIn} GoogleBtn={GoogleBtn} user={userInState} />
          </Route>
          <Route path="/user">
            {isSignedIn ? <UserDetails SignOutBTn={SignOutBTn} user={userInState} /> : <Redirect to="/" exact />}
          </Route>
        </Switch>

      </Router>
    </div>
  );
}

export default App;
