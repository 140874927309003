import React from 'react'
import styled from '@emotion/styled'

const UserCard = styled.div`
  max-width: 300px;
  border: 2px soild #cfcfcf;
`

const D = styled.span`
  font-weight: bold;
  margin-right: 10px;
`

const User = ({ name, email, phoneNo, city, instagramId }) => {


  return (
    <UserCard className="card m-4 p-2" style={{ flexShrink: "0", flexGrow: "1", flexBasis: "300px" }}>
      <h5 className="card-header">{name}</h5>
      <div className="card-body">
        <div className="">
          <div><D>City:</D> {city}</div>
          <div><D>E-Mail:</D> {email}</div>
          <div>
            <D>Instagram ID:</D>
            <a href={`https://instagram.com/${instagramId}`}>{instagramId}</a>
          </div>
          <div><D>Phone Number:</D> {phoneNo}</div>
        </div>
      </div>
    </UserCard>
  )
}

export default User
