import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyD4bGCyPVL5DQ5L1w8-GR8umYjO2m2pkII",
  authDomain: "my-plasma-de402.firebaseapp.com",
  projectId: "my-plasma-de402",
  storageBucket: "my-plasma-de402.appspot.com",
  messagingSenderId: "415000522405",
  appId: "1:415000522405:web:73cbfb647f20e3577fa74c",
  measurementId: "G-YV0GS3QQRV"
};

firebase.initializeApp(firebaseConfig)


// for authentication
// const auth = firebase.auth()

// const provider = new firebase.auth.GoogleAuthProvider();
// provider.setCustomParameters({ prompt: 'select_account' });
// export const signInWithGoogle = () => auth.signInWithPopup(provider);

// database
// const database = firebase.firestore()

export default firebase;


