import React, { useState, useEffect } from 'react'
import allCitiesImported from '../Data/IndianCities.json'
import styled from '@emotion/styled'


// 
import { toast } from 'react-toastify'

//database
import firebase from '../services/firebase'
const database = firebase.firestore()



const CustomSelect = styled.div`
  max-height: 200px;
  overflow-Y: scroll;
  overflow-X: hidden;
  margin-top: 10px;
  border: 2px solid #cfcfcf;
  display: ${props => props.open ? "block" : "none"};

  & > div{
    height: 2.3rem;
    padding: 5px;
    border-bottom: 1px solid #cfcfcf;
    margin: 5px;
    cursor: pointer;
  }
`


const UserForm = ({ user, uid }) => {


  const [allCities, setAllCities] = useState([])

  const [phoneNo, setPhoneNo] = useState(user.phoneNo)
  const [city, setCity] = useState(user.currentCity)
  const [isDonating, setIsDonating] = useState(user.isDonating)

  const [instagramId, setInstagramId] = useState(user.instagramId)

  useEffect(() => {
    setPhoneNo(user.phoneNo)
    setIsDonating(user.isDonating)
    setCity(user.currentCity)
    setInstagramId(user.instagramId)
  }, [user.phoneNo, user.currentCity, user.isDonating, user.instagramId])

  useEffect(() => {
    setAllCities(allCitiesImported)
  }, [])


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [isPhoneError, setIsPhoneError] = useState(false)
  const [isCityError, setIsCityError] = useState(false)

  const handleFormSubmit = (e) => {
    e.preventDefault();
    database.collection("users").doc(uid).update({
      phoneNo: phoneNo,
      currentCity: city,
      isDonating: isDonating,
      instagramId: instagramId
    }).then(() => {
      toast.success("Updated.")
      console.log("Details Saved...")
      console.log("form submit")
      window.location.reload();
    }).catch(error => {
      toast.error("Try Again")
      console.log("error in saving user details", error)
    })
  }




  const checkInputForError = (value, name) => {
    if (name === "phoneNo") {
      if (value.length === 0) setIsPhoneError(false)
      if (value.length !== 10 && value.length > 0) setIsPhoneError(true)
      else setIsPhoneError(false)
    }
    if (name === "city") {
      if (allCitiesImported.some(city => city === value)) {
        setIsCityError(false)
      } else setIsCityError(true)
    }
  }

  const sortCities = (value) => {
    if (value.trim() === "") {
      setAllCities(allCitiesImported);
      return;
    }
    const sortedCities = allCitiesImported.filter(city => city.toLowerCase().includes(value.toLowerCase()))
    setAllCities(sortedCities);
  }


  const handleInputChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (name === "phoneNo") { setPhoneNo(value); checkInputForError(value, name) }
    else if (name === "city") {
      setCity(value);
      sortCities(value);
      checkInputForError(value, name);
    } else if (name === "instagramId") {
      setInstagramId(value)
    }
  }

  const handleSelectClick = (e) => {
    const value = e.target.innerHTML;
    setCity(value);
    checkInputForError(value, "city")
    setIsDropdownOpen(false)
  }

  const handleCityInputFocus = () => {
    setIsDropdownOpen(true);
  }

  const handleCheckBox = (e) => {
    setIsDonating(isDonating => !isDonating)
  }


  let error = isPhoneError || isCityError;

  return (
    <div styles={{ width: "400px" }}>
      <form>
        {/* instagram ID */}
        <div className="mb-3 mt-4">
          <label className="form-label" htmlFor="instagramId">Instagram ID: </label>
          <input className="form-control" value={instagramId} type="text" name="instagramId" id="instagramId" onChange={handleInputChange} />
        </div>
        {/* phone number */}
        <div className="mb-3">
          <label className="form-label" htmlFor="phoneNo">Phone Number: </label>
          <input className="form-control" value={phoneNo} type="text" name="phoneNo" id="phoneNo" onChange={handleInputChange} />
          {isPhoneError && <span className="text-red small" >Number Must Be Of 10 Digits.</span>}
        </div>
        {/* Current City */}
        <div className="mb-3">
          <label className="form-label" htmlFor="city">Current City </label>

          <input className="form-control" value={city} type="text" name="city" id="city" onChange={handleInputChange} onFocusCapture={handleCityInputFocus} />

          {isCityError && <span className="text-red small">Please select a city from the Dropdown</span>}

          <CustomSelect open={isDropdownOpen} >
            {allCities.map((city, index) => (
              <div onClick={handleSelectClick} key={`${city}-${index}`}>{city}</div>
            ))}
          </CustomSelect>
        </div>

        {/* Is Donating */}
        <div className="form-check form-switch mb-3">
          <input onChange={handleCheckBox} className="form-check-input" type="checkbox" id="isDonating" checked={isDonating} />
          <label className="form-check-label" htmlFor="isDonating">Are you Donating Right Now</label>
        </div>

        <button disabled={error} onClick={handleFormSubmit} className={`btn btn-primary ${error ? "disabled" : ""}`}>Update</button>
      </form>

    </div>
  )
}

export default UserForm
